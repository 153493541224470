import React from 'react'
import {
  About,
  Breadcrumbs,
  Bullets,
  Director,
  Figures,
  Hero,
  Page,
} from '@components'

const meta = {
  title: 'O nama | Protupožarna zaštita - FSB d.o.o.',
  description: 'Tvrtka FSB d.o.o. nudi kvalitetna sigurnosna rješenja za zaštitu ljudskih života i imovine uz kompletnu podršku u izradi građevinsko – tehničkih koncepcija protupožarne zaštite.',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/o-nama/', name: 'O nama'},
      ]}
    />
    <Hero
      title="O nama"
      subtitle="Tržišno orijentirani pružatelj optimalnih rješenja pasivne protupožarne zaštite"
      description={meta.description}
    />
    <Bullets />
    <Figures />
    <About />
    <Director />
  </Page>
)

export default Homepage